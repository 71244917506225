import type { ComponentProps, Ref } from "react";
import { forwardRef } from "react";
import clsx from "clsx";
import { Link } from "wouter";

import { Icon } from "@sunrise/icons";

import { Spinner } from "../spinner";
import { Text } from "../text";
import { VisuallyHidden } from "../visually-hidden";
import styles from "./button.module.css";
import type { ButtonOrLinkProps } from "./types";

export type AnchorProps = { href: string } & Omit<
  ComponentProps<typeof Link>,
  "to" | "href"
> &
  ButtonOrLinkProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement>;

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonOrLinkProps;

type PolymorphicProps =
  | (ButtonProps & { as: "button" })
  | (AnchorProps & { as: "a" });

function isLinkProps(
  props: Omit<ButtonOrLinkProps, "children">,
): props is AnchorProps {
  return "href" in props;
}

function isButtonProps(
  props: Omit<ButtonOrLinkProps, "children">,
): props is ButtonProps {
  return !("href" in props);
}

const ButtonOrLink = forwardRef<
  HTMLAnchorElement | HTMLButtonElement | null,
  PolymorphicProps
>(function ButtonOrLink(
  {
    as: _as,
    icon,
    hideLabel,
    children,
    iconPosition,
    variant = "filled",
    className,
    loading,
    active,
    ...props
  }: PolymorphicProps,
  ref,
) {
  const mergedClassName = clsx(
    variant !== "none" && [
      styles.button,
      styles[variant],
      icon && !hideLabel && styles.withIcon,
      icon && hideLabel && styles.iconOnly,
      icon && iconPosition === "end" && styles.reversed,
      active && styles.active,
    ],
    className,
  );

  const contents = (
    <>
      {icon && <Icon className={styles.icon} name={icon} />}
      {hideLabel ? (
        <VisuallyHidden>{children}</VisuallyHidden>
      ) : variant === "none" ? (
        children
      ) : typeof children === "string" ? (
        <Text size="large" variant="label">
          {children}
        </Text>
      ) : (
        children
      )}
    </>
  );

  const title =
    hideLabel && typeof children === "string" ? children : undefined;

  if (isLinkProps(props)) {
    const isExternal = props.href.toString().startsWith("http");
    const rel = isExternal ? "noopener nofollow" : props.rel;
    const target = isExternal ? "_blank" : props.target;

    const anchor = (
      <a
        ref={ref as Ref<HTMLAnchorElement>}
        className={mergedClassName}
        rel={rel}
        target={target}
        title={title}
        {...props}
      >
        {contents}
      </a>
    );

    if (isExternal) return anchor;

    return (
      <Link {...props} asChild>
        {anchor}
      </Link>
    );
  }

  if (isButtonProps(props)) {
    return (
      <button
        {...props}
        ref={ref as Ref<HTMLButtonElement>}
        className={mergedClassName}
        data-testid={props["data-testid"]}
        title={title}
        type={props.type ?? "button"}
      >
        {loading ? <Spinner variant="small" isActive /> : contents}
      </button>
    );
  }

  return null;
});

export { ButtonOrLink };
