import { keepPreviousData } from "@tanstack/query-core";
import areEqual from "fast-deep-equal";
import { atomFamily } from "jotai/utils";
import { atomWithSuspenseQuery } from "jotai-tanstack-query";

import type { ContinueWatchingStatusSchema } from "@sunrise/backend-ng-continue-watching";
import { ngContinueWatchingApiAtom } from "@sunrise/backend-ng-continue-watching";
import { queryKeys } from "@sunrise/backend-types";
import type { EPGEntryId, RecordingId } from "@sunrise/backend-types-core";
import { selectJwtUserToken } from "@sunrise/jwt";

export const continueWatchingEpgByEpgIdorRecordingIdQueryAtom = atomFamily(
  (props: { epgId: EPGEntryId } | { recordingId: RecordingId }) => {
    return atomWithSuspenseQuery<ContinueWatchingStatusSchema | null>((get) => {
      const ngApi = get(ngContinueWatchingApiAtom);
      const userToken = get(selectJwtUserToken);

      return {
        retry: false,
        queryKey: [
          ...queryKeys.userToken(userToken),
          "continue-watching-state",
          "epgId" in props ? props.epgId : props.recordingId,
        ],
        queryFn: async () => {
          const response =
            await ngApi.continueWatching.getContinueWatchingStatusContinueWatchingV1ContinueWatchingStatusGet(
              "epgId" in props
                ? { epg_entry_id: props.epgId }
                : { recording_id: props.recordingId },
              {
                validateStatus: function (status) {
                  return (status >= 200 && status < 300) || status === 404;
                },
              },
            );

          if (response.status === 404) {
            return null;
          }

          return response.data;
        },
        gcTime: 60 * 1000 * 5, // 5 minutes
        staleTime: 60 * 1000 * 10, // 10 minutes
        refetchOnWindowFocus: "always",
        refetchOnMount: "always",
        placeholderData: keepPreviousData,
      };
    });
  },
  areEqual,
);
