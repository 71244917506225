import { useEffect, useState } from "react";
import { useAtomValue } from "jotai";

import { playerAtom } from "@sunrise/player";
import {
  processVisibilityAtom,
  selectProcessIsBackgrounded,
} from "@sunrise/process-visibility";
import { deviceInfo } from "@sunrise/utils";
import { useRecoverPlayout } from "@sunrise/yallo-autostart";

/**
 * Especially on the live-only embedded player, We want to prevent the user to somehow pause the live stream and continue in the past
 * Will attempt to restart the player's playRequest when the player has been foregrounded,
 * was previously paused by the system, but wasn't paused by the user.
 */
export function useAutoreload(): void {
  // Nothing has to happen when we are backgrounded.
  // don't use selectPlayerShouldBePaused() because it will rerender infinitely
  const recoverPlayout = useRecoverPlayout();

  const player = useAtomValue(playerAtom);
  const visibility = useAtomValue(processVisibilityAtom);
  const isBackgrounded = useAtomValue(selectProcessIsBackgrounded);
  const { shouldBePaused, isAutoStopped, state } = player;

  const isPlayerIdle = state === "idle";
  const isPlayerPaused = state === "paused";

  const [shouldRecoverNextTime, setShouldRecoverNextTime] = useState(false);

  const msInBackground = 1000; // 1 second

  useEffect(() => {
    if (isAutoStopped) return;

    // NOTE: we don't want this on desktop
    if (!deviceInfo.isMobileOrTablet) return;

    if (
      !shouldBePaused &&
      isBackgrounded &&
      isPlayerPaused &&
      !shouldRecoverNextTime
    ) {
      setShouldRecoverNextTime(true);
      return;
    }

    if (
      isBackgrounded ||
      shouldBePaused ||
      isPlayerIdle ||
      !shouldRecoverNextTime
    ) {
      return;
    }

    setShouldRecoverNextTime(false);

    // When there is no backgroundedAt time it means we did not spend any time in the background yet.
    if (!visibility.lastBackgroundedAt) return;

    const timeSpentInBackgroundInMs =
      visibility.lastForegroundedAt.getTime() -
      visibility.lastBackgroundedAt.getTime();

    if (timeSpentInBackgroundInMs >= msInBackground) {
      recoverPlayout({ isPaused: isPlayerPaused });
    }
  }, [
    shouldRecoverNextTime,
    isBackgrounded,
    shouldBePaused,
    isPlayerIdle,
    visibility,
    isAutoStopped,
    isPlayerPaused,
    recoverPlayout,
  ]);
}
