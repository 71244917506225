import type {
  AssetId,
  ChannelGroupId,
  ChannelId,
  EPGEntryId,
  Language,
  RecordingGroupId,
  TimeDay,
  UserToken,
} from "@sunrise/backend-types-core";
import { type Nullable } from "@sunrise/utils";

import type { StaffType } from "./asset.types";
import type {
  RecordingGroupSort,
  RecordingsSort,
  RecordingStatusFilter,
  RecordingTypeFilter,
} from "./recordings.types";
import type { SearchQuery } from "./search.types";

const getUserTokenQueryKey = (at: Nullable<UserToken>) => ["at", at] as const;
const getNgOrLegacyQueryKey = (ng: boolean) =>
  ["be", ng ? "ng" : "legacy"] as const;

const recordingsBase = (
  at: Nullable<UserToken>,
  ng: boolean,
  language: "all" | Language,
) =>
  [
    ...getUserTokenQueryKey(at),
    ...getNgOrLegacyQueryKey(ng),
    "recordings",
    language,
  ] as const;

/**
 * When knowing the latest state relies on polling for the data use this as the base key.
 */
const recordingsPullBase = (
  at: Nullable<UserToken>,
  ng: boolean,
  language: "all" | Language,
) => [...recordingsBase(at, ng, language), "pull"] as const;

/**
 * We initially do a pull but then the state is pushed to us through the websocket.
 * So we should not flush it on the same interval as the "pull-based" recordings.
 */
const recordingsPushBase = (
  at: Nullable<UserToken>,
  ng: boolean,
  language: "all" | Language,
) => [...recordingsBase(at, ng, language), "push"] as const;

export const queryKeys = {
  assetByIdLegacy: (assetId: Nullable<AssetId>) => ["assets", assetId],
  assetById: (assetId: Nullable<AssetId>, language?: Language) => [
    "assets",
    assetId,
    language,
  ],
  assetStaffById: (assetId: Nullable<AssetId>, types: StaffType[] = []) => [
    "assets",
    assetId,
    "staff",
    types.join(","),
  ],
  assetRatingById: (assetId: Nullable<AssetId>) => [
    "assets",
    assetId,
    "rating",
  ],
  assetEpisodesById: (
    assetId: Nullable<AssetId>,
    language: Language,
    sortBy: Nullable<string>,
    availability: Nullable<string>,
    channel: Nullable<ChannelId>,
    season: Nullable<number>,
  ) => [
    "assets",
    assetId,
    language,
    sortBy,
    availability,
    channel,
    season,
    "episodes",
  ],
  assetAlternateAiringsById: (
    assetId: Nullable<AssetId>,
    language: Language,
  ) => ["assets", assetId, language, "alternateAirings"],
  assetFiltersByAssetId: (assetId: Nullable<AssetId>, language: Language) => [
    "assets",
    assetId,
    language,
    "filters",
  ],
  alternateAiringsById: (epgId: Nullable<EPGEntryId>, language: Language) => [
    epgId,
    language,
    "alternateAirings",
  ],

  epgById: (epgId: Nullable<EPGEntryId>, language: Language) => [
    "epg",
    epgId,
    language,
  ],

  userToken: getUserTokenQueryKey,

  user: (at: Nullable<UserToken>) =>
    [...getUserTokenQueryKey(at), "user"] as const,

  channelPackages: (at: Nullable<UserToken>) =>
    [...getUserTokenQueryKey(at), "channel-packages"] as const,

  channelGroups: (at: Nullable<UserToken>) =>
    [...getUserTokenQueryKey(at), "channel-groups"] as const,

  channelGroupChannelsPage: (
    at: Nullable<UserToken>,
    channelGroupId: ChannelGroupId,
    language: Language,
    page: number | string,
  ) => [
    ...getUserTokenQueryKey(at),
    "channel-group-channels-page",
    channelGroupId,
    language,
    page,
  ],

  channelEpgCollection: (
    channelId: Nullable<ChannelId>,
    language: Language,
    day: TimeDay,
  ) => ["channel", channelId, language, day],

  // recordings
  recordingsBase,
  recordingsPullBase,
  recordingStats: (at: Nullable<UserToken>, ng: boolean) =>
    [
      // NOTE: It's only temporary that NG uses the pull base.
      //       As soon as the NG websocket is implemented we should wire it up to work through push again.
      ...(ng ? recordingsPullBase : recordingsPushBase)(at, ng, "all"),
      "recording-stats",
    ] as const,

  recordingByRecordingId: (
    at: Nullable<UserToken>,
    ng: boolean,
    language: Language | "all",
    recordingId: string,
  ) =>
    [
      ...recordingsPullBase(at, ng, language),
      "recordings-per-id",
      recordingId,
    ] as const,
  recordingsStatus: (at: Nullable<UserToken>, ng = false) =>
    [...recordingsPushBase(at, ng, "all"), "recordings-status"] as const,
  recordingStatusByEpgEntryId: (
    at: Nullable<UserToken>,
    epgEntryId: EPGEntryId,
  ) => [...recordingsPushBase(at, true, "all"), epgEntryId],
  recordingsOverviewLegacy: (
    at: Nullable<UserToken>,
    sort: RecordingsSort,
    language: string,
    typeFilter: RecordingTypeFilter,
    statusFilter: RecordingStatusFilter,
  ) =>
    [
      ...recordingsPullBase(at, false, "all"),
      "recordings-overview",
      sort,
      language,
      statusFilter,
      typeFilter,
    ] as const,
  /**
   * NOTE: This is a separate query key since the filter & sorting types are not the same as the legacy one.
   */
  recordingsOverview(
    at: Nullable<UserToken>,
    language: Language,
    sort: string,
    filter: string,
    status?: string,
  ) {
    return [
      ...recordingsPullBase(at, true, language),
      language,
      "recordings-overview",
      sort,
      filter,
      status,
    ] as const;
  },
  haveRecordingSchedules: (at: Nullable<UserToken>, assetId: string) =>
    [
      ...recordingsPullBase(at, false, "all"),
      "have-recording-schedules",
      assetId,
    ] as const,
  recordingGroupItems: (
    at: Nullable<UserToken>,
    assetId: string,
    sort: RecordingGroupSort,
    traverseChild: boolean,
  ) =>
    [
      ...recordingsPullBase(at, false, "all"),
      "recording-group-items",
      assetId,
      sort.field,
      sort.direction,
      traverseChild ? "traverse" : "no-traverse",
    ] as const,
  recordingItemsByAssetId: (at: Nullable<UserToken>, assetId: string) =>
    [
      ...recordingsPullBase(at, false, "all"),
      "recording-items-by-asset-id",
      assetId,
    ] as const,
  // TODO: doublecheck if that is still used ...
  haveRecordings: (at: Nullable<UserToken>, assetId: string) =>
    [
      ...recordingsPullBase(at, false, "all"),
      "have-recordings",
      assetId,
    ] as const,
  recordingGroupsByRecordingGroupId: (
    at: Nullable<UserToken>,
    recordingGroupId: RecordingGroupId,
  ) =>
    [
      ...recordingsPullBase(at, false, "all"),
      "recording-groups-by-recording-group-id",
      recordingGroupId,
    ] as const,

  recordingByRecordingIdNg: (
    at: Nullable<UserToken>,
    recordingId: string,
    language: Language,
  ) =>
    [
      ...recordingsPullBase(at, true, language),
      "recordings-per-id",
      recordingId,
    ] as const,

  unfilteredRecordingGroupEpisodes: (
    at: Nullable<UserToken>,
    recordingGroupId: string,
    language: Language,
  ) =>
    [
      ...recordingsPullBase(at, true, language),
      "recording-group-episodes",
      recordingGroupId,
    ] as const,

  recordingGroupEpisodes: (
    at: Nullable<UserToken>,
    recordingGroupId: string,
    language: Language,
    sortBy: Nullable<string>,
    status: Nullable<string>,
    channel: Nullable<ChannelId>,
    season: Nullable<number>,
  ) =>
    [
      ...recordingsPullBase(at, true, language),
      "recording-group-episodes",
      recordingGroupId,
      sortBy,
      status,
      channel,
      season,
    ] as const,
  recordingGroupFilters: (
    at: Nullable<UserToken>,
    recordingGroupId: RecordingGroupId,
    language: Language,
  ) => [
    ...recordingsPullBase(at, true, language),
    "recording-group-filters",
    recordingGroupId,
  ],

  continueWatching: (at: Nullable<UserToken>, ng = false) =>
    [
      ...getUserTokenQueryKey(at),
      ...getNgOrLegacyQueryKey(ng),
      "continue-watching-paged",
    ] as const,
  continueWatchingRecommendations: (at: Nullable<UserToken>) =>
    [
      ...getUserTokenQueryKey(at),
      ...getNgOrLegacyQueryKey(true),
      "continue-watching-recommendations",
    ] as const,
  fullyWatched: (at: Nullable<UserToken>) =>
    [...getUserTokenQueryKey(at), "fully-watched"] as const,

  searchEpgItems: (filter: SearchQuery["filter"], query: string) =>
    ["search-epg", filter, query] as const,
  searchChannels: (query: string) => ["search-channels", query] as const,
  searchHistory: (at: Nullable<UserToken>, ng: boolean) =>
    [
      ...getUserTokenQueryKey(at),
      ...getNgOrLegacyQueryKey(ng),
      "search-history",
    ] as const,
  search: (query: string, filter: string) => ["search", query, filter] as const,

  upsellLink: (at: Nullable<UserToken>, language: Language, context: string) =>
    [...getUserTokenQueryKey(at), "upsell-link", language, context] as const,
};
