import { atom } from "jotai";
import { uniqWith } from "lodash";

import type { ContinueWatchingItemLegacy } from "@sunrise/backend-types";
import type { ContinueWatchingId } from "@sunrise/backend-types-core";
import { isDefined } from "@sunrise/utils";

import { continueWatchingSocketUpdatesLegacyAtom } from "../socket/continue-watching-socket-updates.legacy.atom";
import { continueWatchingInfiniteQueryLegacyAtom } from "./continue-watching-infinite-query.legacy.atom";

/**
 * A separate atom just to cache the flatMapped result a bit.
 */
const continueWatchingFetchedItemsAtom = atom((get) => {
  const items = get(continueWatchingInfiniteQueryLegacyAtom);

  return (
    items.data?.pages
      .filter(isDefined)
      .map((page) => page.result)
      .flat() ?? []
  );
});

/**
 * Known items from the continue watching infinite query.
 * This will make it a bit more performant when we query continue watching items for multiple programs.
 * They should use a cached version of the flattened results so we do not repeat that operation every time.
 *
 * It will also make sure to update the CW items through data delivered in the websocket.
 * This can be done by writing to the continueWatchingSocketUpdatesAtom.
 *
 * TODO: Make sure this works OK for NG. Maybe make a purer atom that does not contain any asset details but which is purely kept for progress tracking.
 *       Since this is too reliant on the epg / asset details being there on updates. And most of the time we will not care about such finegrained details.
 *
 * @deprecated
 *    To be removed as soon as legacy is removed.
 */
export const continueWatchingKnownItemsLegacyAtom = atom((get) => {
  const fetchedItems = get(continueWatchingFetchedItemsAtom);

  const [deletes, updates] = get(
    continueWatchingSocketUpdatesLegacyAtom,
  ).reduce(
    (acc, update) => {
      if (update.type === "delete") {
        acc[0].push(update.id);
      } else {
        acc[1].push(update);
      }

      return acc;
    },
    [[], []] as [ContinueWatchingId[], ContinueWatchingItemLegacy[]],
  );

  const fetchedItemsWithoutDeletes = fetchedItems
    .map((item) => {
      const deleted = deletes.some((id) => id === item.id);

      if (deleted) {
        return null;
      }

      return item;
    })
    .filter(isDefined);

  return uniqWith(
    [...updates, ...fetchedItemsWithoutDeletes],
    (a, b) =>
      a.id === b.id || (a.epg_entry.id === b.epg_entry.id && a.type === b.type),
  );
});
