import type { ContinueWatchingLegacyUpdate } from "@sunrise/backend-types";
import { atomWithArrayUpdates } from "@sunrise/utils";

/**
 * @deprecated
 *   To be replaced with a new set of socket updates. This is to be removed as soon as the legcay endpoints are removed.
 */
export const continueWatchingSocketUpdatesLegacyAtom =
  atomWithArrayUpdates<ContinueWatchingLegacyUpdate>([], {
    compareFn: (a, b) =>
      a.id === b.id ||
      ("epg_entry" in a &&
        "epg_entry" in b &&
        a.epg_entry.id === b.epg_entry.id),
    debugLabel: "contineWatchingSocketUpdates",
  });
