import { atom } from "jotai";

import {
  playerAtom,
  selectPlayerCurrentPlayRequest,
  selectPlayerIsAutoStopped,
} from "@sunrise/player";
import { playerManagerAtom } from "@sunrise/yallo-common-player-manager";

import { disableAutoStartAtom } from "./disable-auto-start.atom";

export const shouldPlayerAutostartAtom = atom((get) => {
  return (
    !get(disableAutoStartAtom) &&
    get(playerAtom).state === "idle" &&
    !get(selectPlayerCurrentPlayRequest) &&
    !get(playerManagerAtom).playRequest &&
    !get(selectPlayerIsAutoStopped)
  );
});
