import { atom } from "jotai";
import { atomEffect } from "jotai-effect";

import type { EPGEntryId, RecordingId } from "@sunrise/backend-types-core";
import { socketAtom, type SocketMessages } from "@sunrise/yallo-websocket";

type ContinueWatchingSocketPayload =
  SocketMessages["continue_watching"]["payload"];

const internalContinueWatchingByEpgEntryAtom = atom<
  Record<EPGEntryId, ContinueWatchingSocketPayload>
>({});
const internalContinueWatchingByRecordingAtom = atom<
  Record<RecordingId, ContinueWatchingSocketPayload>
>({});

/**
 * This atom is used to get the continue watching status from the socket.
 * It is updated by the listenForContinueWatchingSocketUpdatesEffect.
 *
 * The data returned are the complete websocket messages including the envelope wrapper.
 *
 * Only when an epgId is provided, the atom will be updated.
 */
export const continueWatchingByEpgEntryAtom = atom<
  Record<EPGEntryId, ContinueWatchingSocketPayload>
>((get) => {
  get(listenForContinueWatchingSocketUpdatesEffect);
  return get(internalContinueWatchingByEpgEntryAtom);
});

/**
 * This atom is used to get the continue watching status from the socket.
 * It is updated by the listenForContinueWatchingSocketUpdatesEffect.
 *
 * The data returned are the complete websocket messages including the envelope wrapper.
 *
 * Only when a recordingId is provided, the atom will be updated.
 */
export const continueWatchingByRecordingAtom = atom<
  Record<RecordingId, ContinueWatchingSocketPayload>
>((get) => {
  get(listenForContinueWatchingSocketUpdatesEffect);
  return get(internalContinueWatchingByRecordingAtom);
});

const listenForContinueWatchingSocketUpdatesEffect = atomEffect((get, set) => {
  const socket = get(socketAtom);

  if (!socket) return;

  const unsubscribe = socket.on("continue_watching", (message) => {
    const { epg_entry_id, recording_id } = message.payload;

    if (epg_entry_id) {
      set(internalContinueWatchingByEpgEntryAtom, (prev) => ({
        ...prev,
        [epg_entry_id]: message,
      }));
    }

    if (recording_id) {
      set(internalContinueWatchingByRecordingAtom, (prev) => ({
        ...prev,
        [recording_id]: message,
      }));
    }
  });

  return () => {
    unsubscribe();
    set(internalContinueWatchingByEpgEntryAtom, {});
    set(internalContinueWatchingByRecordingAtom, {});
  };
});
