import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import type { RecordingId } from "@sunrise/backend-types-core";

import { continueWatchingByRecordingIdAtom } from "./legacy/continue-watching-by-recording-id.atom";
import { continueWatchingItemLegacyToContinueWatchingStatus } from "./legacy/helpers/continue-watching-item-legacy-to-continue-watching-status";
import { continueWatchingStatusByEpgIdOrRecordingIdAtom } from "./ng/continue-watching-status-by-epg-id-or-recording-id.atom";
import type { ContinueWatchingStatus } from "./types";

export const continueWatchingStatusByRecordingIdAtom = atomFamily(
  (recordingId: RecordingId) => {
    return atom<Promise<ContinueWatchingStatus | null>>(async (get) => {
      if (get(isLegacyBackendAtom)) {
        // NOTE: FW is not supported for recordings on legacy without mapping the recordingId to an epgId which we don't care for much.
        const status = get(continueWatchingByRecordingIdAtom(recordingId));

        return continueWatchingItemLegacyToContinueWatchingStatus(status);
      }

      return get(
        continueWatchingStatusByEpgIdOrRecordingIdAtom({ recordingId }),
      );
    });
  },
);
