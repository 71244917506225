import debounce from "lodash/debounce";

import type { ChannelId } from "@sunrise/backend-types-core";
import { errorAtom } from "@sunrise/error";
import { selectIsLoggedIn } from "@sunrise/jwt";
import {
  getStreamModelForPlayRequest,
  playerCurrentDateTimeAtom,
  playerDateTimeConverterAtom,
  selectPlayerCurrentPlayRequest,
} from "@sunrise/player";
import { PlayerManager, type PlayRequestSource } from "@sunrise/player-manager";
import { type Store } from "@sunrise/store";
import { type Nullable } from "@sunrise/utils";
import { isChannelLockedAtom } from "@sunrise/yallo-channel-group";
import type { PlayRequest } from "@sunrise/yallo-player-types";
import {
  type ActiveReplayChannel,
  activeReplayChannelAtom,
} from "@sunrise/yallo-replay";
import type { UpsellError } from "@sunrise/yallo-upsell";
import { freeUsageAtom } from "@sunrise/yallo-user";

import { createPlayRequestToAdPlayout } from "./create-play-request-to-ad-playout";
import { createPlayRequestToStream } from "./create-play-request-to-stream";
import {
  actionPlayerManagerLoadPlayRequest,
  actionPlayerManagerReset,
  actionPlayerManagerSetError,
  playerManagerAtom,
  selectPlayerManagerCurrentPlayRequest,
} from "./player-manager.atom";
import { PlayerManagerGuard } from "./player-manager-guard";
import { playerPermissionsAtom } from "./player-permissions.atom";
import { type PlayerManagerPermissions } from "./yallo-common-player-manager.types";
import { createYalloEPGSeekService } from "./yallo-epg-seek-service";
import { yalloLinearSeekAds } from "./yallo-linear-seek-ads";
import { YalloPlayerDispatch } from "./yallo-player-dispatch";
import { createYalloRecordingSeekService } from "./yallo-recording-seek-service";

// debounce time used in a play request
const DEBOUNCE_TIME = 800;

function onUpsellError(store: Store) {
  return (err: UpsellError): void => {
    store.set(playerManagerAtom, actionPlayerManagerSetError(err));
    store.set(errorAtom, err);
  };
}

function getPermissions(store: Store): Nullable<PlayerManagerPermissions> {
  return store.get(playerPermissionsAtom);
}

function checkIfChannelActivatedForReplay(
  store: Store,
  channelId: ChannelId,
): Promise<ActiveReplayChannel> {
  return store.get(activeReplayChannelAtom({ channelId }));
}

let playerManagerInternal: Nullable<PlayerManager<PlayRequest>>;

/**
 * This is a TS-way of limiting what we can do with the PlayerManager in the code.
 * We don't want to expose the load method, because it should only be called by the subscriptions on the store through the `initPlayerManager` function.
 */
export function getPlayerManager(): Omit<PlayerManager<PlayRequest>, "load"> {
  if (!playerManagerInternal) {
    throw new Error("PlayerManager is not initialized");
  }

  return playerManagerInternal;
}

export function initPlayerManager(
  store: Store,
  isDevelopment = false,
  config: { playDebounceTime?: number; hasPauseUpsell?: boolean } = {},
): () => void {
  const guard = new PlayerManagerGuard(
    store,
    getPermissions,
    onUpsellError(store),
    checkIfChannelActivatedForReplay,
    (store, channelId) => {
      return store.get(isChannelLockedAtom(channelId));
    },
    async () => (await store.get(freeUsageAtom)).usageLimitReached,
    {
      hasPauseUpsell: config.hasPauseUpsell ?? true,
    },
  );

  const playerDispatch = new YalloPlayerDispatch(store);

  const getPlayRequestForSource = (
    source: PlayRequestSource,
  ): Nullable<PlayRequest> => {
    if (source === "current") {
      return store.get(selectPlayerCurrentPlayRequest);
    }

    return store.get(playerManagerAtom).playRequest;
  };

  playerManagerInternal = new PlayerManager<PlayRequest>(
    createPlayRequestToStream(store),
    createPlayRequestToAdPlayout(store, getPermissions),
    getPlayRequestForSource,
    playerDispatch,
    guard,
    createYalloEPGSeekService(store, guard),
    createYalloRecordingSeekService(store, guard),
    (request, options) => {
      store.set(
        playerManagerAtom,
        actionPlayerManagerLoadPlayRequest(request, options),
      );
    },
    /**
     * Get the stream model.
     * @param source
     */
    (source) => {
      const pr = getPlayRequestForSource(source);
      if (!pr) {
        return null;
      }

      return getStreamModelForPlayRequest(pr.type);
    },
    (source) => {
      if (source === "requested") {
        throw new Error("not supported");
      }

      return store.get(playerDateTimeConverterAtom);
    },
    () => store.get(playerCurrentDateTimeAtom),
    (currentTime, playRequest, seekTime) => {
      return yalloLinearSeekAds(store, currentTime, playRequest, seekTime);
    },
  );

  store.sub(selectIsLoggedIn, () => {
    const loggedIn = store.get(selectIsLoggedIn);
    if (loggedIn) {
      return;
    }

    // Reset when logging out.
    store.set(playerManagerAtom, actionPlayerManagerReset());
  });

  const debouncedPlay = debounce(
    (playRequest, loadOptions) => {
      if (!playerManagerInternal) {
        throw new Error("PlayerManager is not initialized");
      }

      playerManagerInternal
        .load(playRequest, loadOptions)
        .catch((err: unknown) => {
          if (err === null) {
            // We need to make sure we reset the playerManagerAtom when the playerManager.load promise resolves with null.
            // Because otherwise we will end up in a continuous loop of loading the same playRequest.
            store.set(playerManagerAtom, actionPlayerManagerReset());
            return;
          }

          if (err instanceof Error) {
            // We need to slap this in another part of the store. Since the player manager error is reset when we log out the user.
            // Which can happen on certain errors.
            store.set(errorAtom, err);

            // We should still store it in playermanager as well since we check this to determine if the current request errored or not.
            store.set(playerManagerAtom, actionPlayerManagerSetError(err));
          }

          if (isDevelopment) {
            console.error("playerManager.play error:", err);
          }
        });
    },
    config.playDebounceTime ?? DEBOUNCE_TIME,
    {
      leading: true,
      trailing: true,
    },
  );

  // Needs to listen ONLY to the playRequest
  return store.sub(selectPlayerManagerCurrentPlayRequest, () => {
    if (!playerManagerInternal) {
      throw new Error("PlayerManager is not initialized");
    }

    const { playRequest, error, loadOptions } = store.get(playerManagerAtom);
    // Only attempt to load if we have a fresh playRequest.
    // If there is an error it means the playRequest was already processed and it failed.
    if (playRequest && error === null) {
      debouncedPlay(playRequest, loadOptions);
    }
  });
}
