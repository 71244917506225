import { featureAtom } from "@sunrise/feature-flags";

const RAW_ENVS = ["development", "test", "local", "staging"];

/**
 * Controls to show either the full content of the error or just a general error message.
 *
 * By default in development and testing we will show the full content of the error.
 * In production we will only show a general error message.
 */
export const errorDisplayContentsFeatureAtom = featureAtom<"raw" | "general">(
  "error-display-contents",
  RAW_ENVS.includes(process.env["NODE_ENV"] ?? "") ? "raw" : "general",
  {
    values: ["raw", "general"],
    description:
      "Controls to show either the full content of the error or just a general error message.",
  },
);
