import { type GeneratedApi } from "@sunrise/backend-ng-video-ad";
import type {
  VideoAdConfig,
  VideoAdPlacementType,
} from "@sunrise/backend-types";

export async function videoAdPlacementsQuery(
  api: GeneratedApi,
  placement: VideoAdPlacementType,
): Promise<VideoAdConfig | null> {
  const response =
    await api.videoAd.getVideoAdPlacementVideoAdV1VideoAdPlacementsPlacementTypeGet(
      placement,
      {
        secure: true,
      },
    );

  const item = response.data;
  if (!item) {
    return null;
  }

  return {
    request_type: placement,
    tags:
      item.tags
        ?.filter((item) => !!item)
        .map((tag) => ({
          tag_url: tag.url,
          impression_id: tag.impression_id,
          placement_id: item.placement_id,
          tracking: {} as Record<string, string>, // not needed on the ng backend
        })) ?? [],
    tag_count: item.tags?.length ?? 0,
    skippable: (item.skip_time ?? 0) > 0,
    skip_time_seconds: item.skip_time ?? 0,
    token_tracking_id: "",
    minutes_without_ads: item.minutes_without_ads ?? 30,
    can_minimize: false,
  };
}
