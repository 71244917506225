import { useCallback } from "react";
import { useAtomCallback } from "jotai/utils";

import { playerAtom, playerDateTimeConverterAtom } from "@sunrise/player";
import { isDefined } from "@sunrise/utils";
import {
  actionPlayerManagerPlayLiveChannelId,
  actionPlayerManagerPlayRecording,
  actionPlayerManagerPlayReplay,
  playerManagerAtom,
} from "@sunrise/yallo-common-player-manager";
import { usePlayStartupChannel } from "@sunrise/yallo-startup-channel";

/**
 * Logic to trigger the recovery of the playout.
 * It will attempt to restart the playRequest and keep the user's current time into account.
 */
export function useRecoverPlayout(): (options?: {
  isPaused?: boolean;
}) => void {
  const startupChannelPlay = usePlayStartupChannel();

  return useAtomCallback(
    useCallback(
      (get, set, { isPaused }: { isPaused?: boolean } = {}) => {
        const { playRequest, currentTime } = get(playerAtom);

        if (!playRequest) {
          startupChannelPlay("autorecover");
          return;
        }

        switch (playRequest.type) {
          case "live":
            set(
              playerManagerAtom,
              actionPlayerManagerPlayLiveChannelId(
                playRequest.channelId,
                "autorecover",
                isPaused,
              ),
            );
            break;
          case "recording":
            set(
              playerManagerAtom,
              actionPlayerManagerPlayRecording(
                playRequest.recordingId,
                playRequest.channelId,
                currentTime ?? playRequest.startAtSeconds,
                isPaused,
                "autorecover",
              ),
            );
            break;
          case "replay": {
            const converter = get(playerDateTimeConverterAtom);

            const time =
              isDefined(currentTime) && isDefined(converter)
                ? converter.toDate(currentTime)
                : null;

            const startTime = time ?? playRequest.startTime;

            if (!startTime) {
              set(
                playerManagerAtom,
                actionPlayerManagerPlayLiveChannelId(
                  playRequest.channelId,
                  "autorecover",
                ),
              );
              break;
            }

            set(
              playerManagerAtom,
              actionPlayerManagerPlayReplay(
                playRequest.epgId,
                playRequest.channelId,
                startTime,
                undefined,
                isPaused,
                "autorecover",
              ),
            );
            break;
          }
        }
      },
      [startupChannelPlay],
    ),
  );
}
