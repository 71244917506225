import { addSeconds } from "date-fns";
import { parse, toSeconds } from "iso8601-duration";

import type { ContinueWatchingStatusSchema } from "@sunrise/backend-ng-continue-watching";
import type {
  EPGEntryId,
  RecordingId,
  TimeISOString,
} from "@sunrise/backend-types-core";

import type { ContinueWatchingStatus } from "../types";

export function parseContinueWatchingStatus(
  data: ContinueWatchingStatusSchema,
): ContinueWatchingStatus | null {
  const seconds = toSeconds(parse(data.progress));

  if (data.recording_id) {
    return {
      type: "recording",
      recordingId: data.recording_id as RecordingId,
      playPositionSecond: seconds,
      isFullyWatched: data.fully_watched ?? false,
    };
  }

  // When we do not have an epg_entry_start in the case of a replay, we cannot calculate the moment.
  if (!data.epg_entry_start || !data.epg_entry_id) {
    return null;
  }

  return {
    type: "replay",
    epgId: data.epg_entry_id as EPGEntryId,
    epgStartTime: data.epg_entry_start as TimeISOString,
    moment: addSeconds(
      new Date(data.epg_entry_start),
      seconds,
    ).toISOString() as TimeISOString,
    isFullyWatched: data.fully_watched ?? false,
  };
}
