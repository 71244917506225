import { atom } from "jotai";
import { atomFamily } from "jotai/utils";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import type { EPGEntryId } from "@sunrise/backend-types-core";

import { continueWatchingByEpgIdLegacyAtom } from "./legacy/continue-watching-by-epg-id.legacy.atom";
import { continueWatchingItemLegacyToContinueWatchingStatus } from "./legacy/helpers/continue-watching-item-legacy-to-continue-watching-status";
import { isFullyWatchedByEpgIdLegacyAtom } from "./legacy/is-fully-watched-by-epg-id.legacy.atom";
import { continueWatchingStatusByEpgIdOrRecordingIdAtom } from "./ng/continue-watching-status-by-epg-id-or-recording-id.atom";
import type { ContinueWatchingStatus } from "./types";

/**
 * Returns if the user has watched this EPG entry or not.
 */
export const continueWatchingStatusByEpgIdAtom = atomFamily(
  (epgId: EPGEntryId) => {
    return atom<Promise<ContinueWatchingStatus | null>>(async (get) => {
      if (get(isLegacyBackendAtom)) {
        const [status, fw] = await Promise.all([
          get(continueWatchingByEpgIdLegacyAtom({ epgId })),
          get(isFullyWatchedByEpgIdLegacyAtom({ epgId })),
        ]);

        return continueWatchingItemLegacyToContinueWatchingStatus(
          status,
          fw,
          epgId,
        );
      } else {
        return get(continueWatchingStatusByEpgIdOrRecordingIdAtom({ epgId }));
      }
    });
  },
);
