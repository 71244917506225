import { atom } from "jotai";

import { selectCanLogin, selectCanUpgrade } from "@sunrise/jwt";

import { upsellLinkQueryAtom } from "./upsell.query";

/**
 * re-exports the value from upsellLinkQueryAtom, but only performs the query if the user is not logged in and a plan upgrade is available.
 */
export const upgradeLinkAtom = atom(async (get) => {
  const canUpgrade = get(selectCanUpgrade);
  const canLogin = get(selectCanLogin);

  if (!canUpgrade || canLogin) return;

  const { data: upgrade } = await get(upsellLinkQueryAtom("upgrade"));

  if (!upgrade) return;

  return upgrade.upsell_link;
});
