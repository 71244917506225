import { AxiosError } from "axios";

/**
 * Outputs as much details as possible about the error for debugging purposes.
 * We show this on the UI in certain cases when we want to inform the user about the technical error.
 * In production cases we will not output any error details.
 *
 * @param error
 * @returns
 */
export function getErrorMessage(error: unknown): string | null {
  if (error instanceof AxiosError) {
    return `${error.code}: ${error.message}\n${
      error.config
        ? `${(error.config.method ?? "UNKNOWN").toUpperCase()} ${error.config.url}${
            error.config.params
              ? `?${new URLSearchParams(error.config.params).toString()}`
              : ""
          }${error.config.data ? `\n${JSON.stringify(error.config.data)}` : ""}`
        : ""
    }`;
  }

  if (
    error instanceof Error ||
    (error instanceof Object &&
      "message" in error &&
      typeof error.message === "string")
  ) {
    return error.message as string;
  }

  if (typeof error === "string") {
    return error;
  }

  return null;
}
