import { atom } from "jotai";
import uniqWith from "lodash/uniqWith";

import { fullyWatchedSocketUpdatesLegacyAtom } from "../socket/fully-watched-socket-updates.legacy.atom";
import { fullyWatchedQueryAtom } from "./fully-watched-query.atom";

/**
 * @deprecated
 *   This will be removed as soon as the legacy code is removed.
 */
export const fullyWatchedItemsLegacyAtom = atom(async (get) => {
  const { data } = await get(fullyWatchedQueryAtom);
  const socket = get(fullyWatchedSocketUpdatesLegacyAtom);

  return uniqWith(
    [...data, ...socket],
    (a, b) => a.epg_entry.id === b.epg_entry.id,
  );
});
