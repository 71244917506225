import { atomEffect } from "jotai-effect";

import { isLegacyBackendAtom } from "@sunrise/backend-core";
import type {
  ContinueWatchingLegacyUpdate,
  FullyWatchedLegacyItem,
} from "@sunrise/backend-types";
import { selectIsLoggedIn } from "@sunrise/jwt";
import { legacySocketAtom } from "@sunrise/yallo-websocket";

import { continueWatchingSocketUpdatesLegacyAtom } from "./continue-watching-socket-updates.legacy.atom";
import { fullyWatchedSocketUpdatesLegacyAtom } from "./fully-watched-socket-updates.legacy.atom";

/**
 * @deprecated
 */
export const reactForContinueWatchingToSocketLegacyEffect = atomEffect(
  (get, set) => {
    if (!get(isLegacyBackendAtom)) {
      return;
    }

    const ws = get(legacySocketAtom);
    const isLoggedIn = get(selectIsLoggedIn);

    if (!ws || !isLoggedIn) {
      return;
    }

    ws.on("continue-watching", (event) => {
      set(
        continueWatchingSocketUpdatesLegacyAtom,
        event as ContinueWatchingLegacyUpdate,
      );
    });

    ws.on("fully-watched", (event) => {
      set(fullyWatchedSocketUpdatesLegacyAtom, event as FullyWatchedLegacyItem);
    });

    return () => {
      // TODO: add a ws.off to the socket.
    };
  },
);
